<template>
  <div>
    <b-table
      small
      :fields="fields"
      :items="items"
      responsive="sm"
      bordered
      head-variant="dark"
    >
      <template #cell(name)="data">
        <b class="">{{ formatKey(data.item.name) }}</b>
      </template>

      <template #cell(nameNumber)="data">
        <b class="text-info">{{ data.item.nameNumber }}  ({{ Currency }})</b>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    BankAccountsTotals: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    Currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Totals' },
        { key: 'nameNumber', label: 'Totals Number' },
      ],
      items: [],
    }
  },
  watch: {
    BankAccountsTotals() {
      this.mapBankAccounts()
    },
  },
  mounted() {
    this.mapBankAccounts()
  },
  methods: {
    formatKey(key) {
      // Format the key to a more readable label (e.g., "cash_in_hand" → "Cash In Hand")
      return key
        .replace(/_/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase())
    },
    mapBankAccounts() {
      this.items = Object.entries(this.BankAccountsTotals).map(([key, value]) => ({
        name: key,
        nameNumber: value,
      }))
    },
  },
}
</script>
