<template>
  <b-sidebar
    id="sidebar"
    v-model="isVisible"
    right
    width="100%"
    @shown="disableBackgroundScroll"
  >
    <div class="w-100 bg-white">
      <b-row class="fixed-top p-1 bg-white box-shadow-bottom">
        <b-col cols="8">        <h6 class="text-nowrap">
          {{ selectedOfficeData.office_name }}
        </h6>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-end"
        ><feather-icon
          icon="XIcon"
          size="25"
          class="cursor-pointer"
          color="blue"
          @click="closeSidebar"
        /></b-col>

      </b-row>

      <b-container
        v-if="selectedOfficeData.bank_accounts"
        class="sidebar-content pt-3 w-100"
      >
        <TableTotals
          :bank-accounts-totals="selectedOfficeData.bank_accounts.totals"
          :currency="selectedOfficeData.bank_accounts.currency"
        />
        <TableBanksAccounts
          :bank-accounts="selectedOfficeData.bank_accounts.accounts"
        />

        <PieChart
          :sources="selectedOfficeData.sources"
          :total-income="selectedOfficeData.total_income"
          :office-name="selectedOfficeData.office_name"
        />

        <BarExpensesChart
          :sources="selectedOfficeData.expense_types"
          :total-expenses="selectedOfficeData.total_expenses"
          :office-name="selectedOfficeData.office_name"
        />

        <BarBillsChart
          :sources="selectedOfficeData.bills"
          :total-bills="selectedOfficeData.total_bills"
          :office-name="selectedOfficeData.office_name"
        />
      </b-container>
    </div>
  </b-sidebar>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import PieChart from './charts-branchs/PieChart.vue'
import BarExpensesChart from './charts-branchs/BarExpensesChart.vue'
import BarBillsChart from './charts-branchs/BarBillsChart.vue'
import TableTotals from './charts-branchs/TableTotalsBanks.vue'
import TableBanksAccounts from './charts-branchs/TableBanksAccounts.vue'

export default {
  components: {
    FeatherIcon, PieChart, BarExpensesChart, TableTotals, TableBanksAccounts, BarBillsChart,
  },
  props: {
    selectedOfficeData: {
      type: Object,
      default: () => ({}),
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.modelValue,
    }
  },
  watch: {
    modelValue(newValue) {
      this.isVisible = newValue
    },
  },
  methods: {
    closeSidebar() {
      this.isVisible = false
      this.$emit('modelValue', this.isVisible)
      document.body.style.overflow = ''
    },
    disableBackgroundScroll() {
      document.body.style.overflow = 'hidden'
    },
  },
}
</script>
<style>
.sidebar-content {
  overflow-y: auto;
}
</style>
