<template>
  <div
    v-if="!totalBills == 0"
    class="pt-5"
  >
    <p class=" text-center text-primary">
      Total Bills : {{ totalBills.toFixed(2) }}
    </p>
    <apexchart
      type="bar"
      height="580"
      :options="chartOptions"
      :series="seriesData"
    />
  </div>
  <div v-else>
    <p class="py-2 text-center text-primary">
      No Bills from {{ officeName }} yet!
    </p>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    totalBills: {
      type: Number,
      default: () => 0,
    },
    officeName: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    seriesData() {
      return [{ data: this.sources.map(source => source.total_bills) }]
    },
    labelsData() {
      return this.sources.map(source => `${source.vendor_name}  (${((source.total_bills / this.totalBills) * 100).toFixed(2)}%)`)
    },
    chartOptions() {
      return {
        chart: {
          width: 320,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
          '#f48024', '#69d2e7',
        ],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            fontSize: '8px',
            fontWeight: 'thin',
            colors: ['black'],
          },
          formatter(val, opt) {
            return `${opt.w.globals.labels[opt.dataPointIndex]}:  ${val}`
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          width: 0.1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: this.labelsData,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter() {
                return ''
              },
            },
          },
        },
      }
    },
  },
}

</script>
