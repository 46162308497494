<template>
  <div v-if="items.length > 0">
    <b-table
      small
      :fields="fields"
      :items="items"
      responsive="sm"
      bordered
      class="table-banks-accounts"
      head-variant="dark"
    />
  </div>
</template>

<script>
export default {
  props: {
    BankAccounts: {
      type: Array,
      default: () => ([]),
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'account_name', label: 'Account Name' },
        { key: 'running_balance', label: 'Running Balance' },
        { key: 'account_type', label: 'Account Type' },
        { key: 'account_number', label: 'Account Number' },
      ],
      items: [],
    }
  },
  watch: {
    BankAccounts: {
      immediate: true,
      handler(newVal) {
        this.items = newVal || []
      },
    },
  },
}
</script>
<style>
.table-banks-accounts .table .thead-dark th{
    background-color: #1ea0fc;
    border-color: #1ea0fc;
    text-wrap: nowrap !important;
}
</style>
