<template>
  <div v-if="!totalIncome == 0">
    <p class=" text-center text-primary">
      Total Income : {{ totalIncome.toFixed(2) }}
    </p>
    <apexchart
      type="pie"
      :options="chartOptions"
      :series="seriesData"
    />
  </div>
  <div v-else>
    <p class="py-2 text-center text-primary">
      No Income from {{ officeName }} yet!
    </p>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    sources: {
      type: Array,
      default: () => [],
    },
    totalIncome: {
      type: Number,
      default: () => 0,
    },
    officeName: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    seriesData() {
      return this.sources.map(source => source.total_income)
    },
    labelsData() {
      return this.sources.map(source => `${source.source} (${(source.total_income).toFixed(2)}) (${((source.total_income / this.totalIncome) * 100).toFixed(2)}%)`)
    },
    chartOptions() {
      return {
        chart: {
          type: 'pie',
        },
        labels: this.labelsData,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 320,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      }
    },
  },
}
</script>
