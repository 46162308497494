<template>
  <div>
    <div v-if="!loader">

      <b-row>
        <b-col
          v-for="(item , index) in officeData"
          :key="item.id"
          cols="12"
          :data-aos="'fade-down'"
          data-aos-easing="linear"
          data-aos-duration="400"
          :data-aos-delay="`${index * 50}`"
        >

          <b-card
            class="text-white animated-border"
            :style="{ background: `${gradientColors[index % gradientColors.length]}`,
                      '--border-color-start': borderColors[index % borderColors.length].start,
            }"

            @click="openSidebar(item)"
          >
            <template #header>
              <h5
                class="mb-0 text-white text-center w-100 text-underline"
                style="font-weight: bold;"
              >
                {{ item.office_name }} - {{ item.office_currency }}
              </h5>
            </template>
            <b-card-text
              class="text-left"
            >
              <strong> Profit : </strong> {{ item.net_profit.toFixed(2) }}
            </b-card-text>
            <b-card-text class="text-left">
              <strong> Total Income : </strong> {{ item.total_income.toFixed(2) }}
            </b-card-text>
            <b-card-text class="text-left">
              <strong> Total Spent :</strong> {{ item.total_spent.toFixed(2) }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <OfficeSidebar
        :model-value="isSidebarOpen"
        :selected-office-data="selectedOfficeData"
        @modelValue="isSidebarOpen = false"
      />
    </div>
    <div
      v-else
      class="text-center pt-5"
    >
      <h3
        class="text-primary"
        style="font-weight: bold; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;"
      >
        Loading . . .
      </h3>
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem; margin-top: 2rem;"
        label="Large Spinner"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AOS from 'aos'
import decryptData from '@/store/auth/decryptData'
import OfficeSidebar from './side-right-bar/OfficeSideBar.vue'

export default {
  components: {
    OfficeSidebar,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      loader: false,
      officeData: [],
      isSidebarOpen: false,
      selectedOfficeData: {},
      gradientColors: [
        'linear-gradient( 135deg, #ff7e5f 0%,  #feb47b  40% , #feb47b  60% , #ff7e5f  100%)',
        'linear-gradient( 135deg, #6a11cb 0%,  #2575fc  40% , #2575fc  60% , #6a11cb  100%)',
        'linear-gradient( 135deg, #11998e 0%,  #38ef7d  40% , #38ef7d  60% , #11998e  100%)',
        'linear-gradient( 135deg, #ff0000 0%,  #ff6666  40% , #ff6666  60% , #ff0000  100%)',
        'linear-gradient( 135deg, #fbc02d 0%,  #ffd54f  40% , #ffd54f  60% , #fbc02d  100%)',
        'linear-gradient( 135deg, #616161 0%,  #bdbdbd  40% , #bdbdbd  60% , #616161  100%)',
        'linear-gradient( 135deg, #1976d2 0%,  #64b5f6  40% , #64b5f6  60% , #1976d2  100%)',
      ],
      borderColors: [
        { start: '#feb47b' },
        { start: '#2575fc' },
        { start: '#38ef7d' },
        { start: '#ff6666' },
        { start: '#ffd54f' },
        { start: '#bdbdbd' },
        { start: '#64b5f6' },
      ],
    }
  },

  mounted() {
    this.getBankAccounts()
    AOS.init({ once: false })
  },
  methods: {
    getBankAccounts() {
      this.loader = true
      axios.get('office-summary').then(res => {
        const { data } = decryptData(res.data.payload)
        this.officeData = data
      }).finally(() => {
        this.loader = false
      })
    },
    openSidebar(data) {
      this.selectedOfficeData = data
      this.isSidebarOpen = true
    },
  },
}
</script>
<style>
.animated-border {
  border: 2px solid;
  animation: borderAnimation 5s infinite linear;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

@keyframes borderAnimation {
  0% {
    border-color: var(--border-color-start);
  }
  50% {
    border-color: #f7f7f7;
  }
  100% {
    border-color: var(--border-color-start);
  }
}
</style>
